import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';

import {Button,Form,Dropdown} from 'react-bootstrap';

import queryString from 'query-string';
import axios from 'axios';
import ReactLoading from 'react-loading';
import BrandsDropDown from '../BrandsDropDown';
import DepartmentDropDown from '../DepartmentDropDown';
import VatDropDown from '../Vatdropdown';
import Modalmsg from '../modalmsg';
import Okmsg from '../Okmsg';
import SuppliersDropDown from './Suppliers/SuppliersDropDown';


 export class EditItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
        sku: null,
        records: null,
        name: '',
        price: 0,
        costprice: 0,
        qty: 0,
        brandid: 0,
        department: 0,
        mpn: '',
        vat: 0.00,
        supplierid: 0,
        hasErrors: false,
        formErrors_sku: '', 
        formErrors_name: '', 
        formErrors_price: '', 
        formErrors_costprice: '', 
        
        formErrors_stock: '', 
        formErrors_mpn: '', 
        formErrors_brand: '', 
        formErrors_dpt: '',
        formErrors_vat: '',
      

        msg: null,
        Title: null,
        ok: false,
        timer: null,
        newitem: false,
        online: false,
        
    }
}

onModalReturn()
{
  this.setState({msg: null,Title: ""});
}

componentDidMount()
 {

  console.log("***");
  console.log(this);

  const searchvalues = queryString.parse(this.props.location.search);
  this.setState({sku: searchvalues.sku});

  var url = this.props.baseUrl+"products/"+searchvalues.sku+"/";

  axios.get(url , {headers: { Authorization: `Bearer `+this.props.jwt}})
  .then(response => {
 
    console.log("Edit item response");
    console.log(response);

    if(response.data['Status']==="OK" && response.data['Count'] === 0)
    {
      console.log("NEW ITEM");
      this.setState({newitem: true, brandid: '0', mpn: '',vat: "1.00", records: true})
      //new item
    }
    else
    {
        console.log("EXISTING ITEM");
    
        this.setState({name: response.data.records[0].stock_name});
        this.setState({price: response.data.records[0].selling_price});
        this.setState({costprice: response.data.records[0].company_price});        
        this.setState({qty: response.data.records[0].stock_quatity});
        this.setState({brandid: response.data.records[0].brandid});
        this.setState({department: response.data.records[0].department});
        this.setState({mpn: response.data.records[0].mpn});
        this.setState({vat: response.data.records[0].vat});
        this.setState({supplierid: response.data.records[0].supplierid})
        this.setState({online: response.data.records[0].online})

        if(response.data.records[0].brandid===null)
          this.setState({brandid: '0'});
        
        if(response.data.records[0].mpn===null || response.data.records[0].mpn==="null")
          this.setState({mpn: ''});
        
        this.setState({records: response.data.records});
    }

    this.forceUpdate();

  })
  .catch(function (error) {
    console.log(error);
  })

 }
  

   onChangeSku(e)
   {
      this.setState({sku: e.target.value});
   }

   onChangeName(e)
   {
    this.setState({name: e.target.value});
   
   }

   onChangePrice(e)
   {
    this.setState({price: e.target.value});
   }

   onChangeCostPrice(e)
   {
    this.setState({costprice: e.target.value});
   }


   onChangeQty(e)
   {
    this.setState({qty: e.target.value});
   
   }

   onChangeMPN(e)
   {
    this.setState({mpn: e.target.value});
   
   }

   onChangeSupplier(e)
   {
    this.setState({supplierid: e});
   
   }


   handleSubmit()
   {
     console.log("submit !!")
     console.log(this.state);

    this.setState({hasErrors: false});

    this.setState({formErrors_sku: ''});
    this.setState({formErrors_name: ''});
    this.setState({formErrors_price: ''});
    this.setState({formErrors_costprice: ''}); 
    this.setState({formErrors_dpt: ''});
    this.setState({formErrors_brand: ''});
    this.setState({formErrors_stock: ''});
    this.setState({formErrors_mpn: ''});
    this.setState({formErrors_vat: ''});
    this.setState({formErrors_supplierid: ''});
    


    var hasErrors = false;

    if(this.state.sku==="")
    {
      console.log("SKu is empty")
      this.setState({formErrors_sku: 'Must not be empty'});
      hasErrors = true;
    }

    if(this.state.name==="")
    {
      this.setState({formErrors_name: 'Must not be empty'});
      hasErrors = true;
    }

    if(this.state.price==="")
    {
      this.setState({formErrors_price: 'Must not be empty'});
      hasErrors = true;
    }

    if(this.state.costprice==="")
    {
      this.setState({formErrors_costprice: 'Must not be empty'});
      hasErrors = true;
    }

    if(this.state.price==="0")
    {
      this.setState({formErrors_price: 'Must not be zero'});
      hasErrors = true;
    }
    if(isNaN(this.state.price))
    {
      this.setState({formErrors_price: 'Not a number'});
      hasErrors = true; 
    }

    if(this.state.department===0)
    {
      this.setState({formErrors_dpt: 'Department is required'});
      hasErrors = true;  
    }

    if(this.state.qty==="")
    {
      this.setState({formErrors_stock: 'Must not be empty'});

      hasErrors = true;
    }

    if(isNaN(this.state.qty))
    {
      this.setState({formErrors_stock: 'Not a number'});

      hasErrors = true;   
    }

    console.log(this.state);

    this.forceUpdate();

    this.setState({hasErrors: hasErrors});

    if(hasErrors === true)
    {
      return;
    }
 
  var url;
  var method;

  if(this.state.newitem===true)
  {
    method='post';
    url = this.props.baseUrl+"products/";
  }
  else
  {
    url = this.props.baseUrl+"products/"+this.state.sku+"/";
    method='patch';
  }

  axios({
    method: method,
    url: url,
    data: {
      barcode: this.state.sku, 
      selling_price: this.state.price,
      company_price: this.state.costprice, 
      stock_quatity: this.state.qty,
      stock_name: this.state.name,
      department: this.state.department,
      mpn: this.state.mpn,  
      brandid: this.state.brandid,
      supplierid: this.state.supplierid,
      vat: this.state.vat,
      online: this.state.online
    },
    headers:
    {
      Authorization: `Bearer `+this.props.jwt,
    }
  }).then(response => {

    console.log("SUBMIT RESPOSE");
    console.log(response.data);

    if(response.data['error_info'][0] !== '00000')
    {
      this.setState({Title: response.data['error_info'][2],msg: 'Error'});
    }
    else
    {
      this.setState({ok: true});
      //this.timer = setTimeout(this.tick.bind(this), 2000);
    }

  })
  .catch(function (error) {
    console.log("SUBMIT ERROR");

    console.log(error);
  })
   }

   
  tick()
  {
    this.props.history.goBack();

  }

   onChangeBrand(e)
   {
     //console.log("onChangeBrand");
     //console.log(e);
     this.setState({brandid: e});
   }

   onChangeDepartment(e)
   {
     //console.log("onChangeDepartment");
     //console.log(e);
     this.setState({department: e});
   }

   onChangeVat(e)
   {
    this.setState({vat: e}); 
   }

   onChangeOnline(e)
   {
    this.setState({online: e});
   }

   errorClass(error) {    
     
    if(!error)
      return '';

    return(error.length === 0 ? '' : 'has-error');
 }

 test()
 {
 }


  render() {

    if(this.state.ok===true)
    {
      return(
          <div>
            <Okmsg />
          </div>
      )
    }

    if(this.state.msg !=  null)
    {
        return(
            <div>
            <Modalmsg ret={this.onModalReturn.bind(this)} Title={this.state.msg} msg={this.state.Title}  />
            </div>
        );

    }

 
    if( this.state.records === null)
    return(
        <div> 
        <h2>Generating your report</h2>
        <ReactLoading type={'spinningBubbles'} color={'#f00'} height={'50%'} width={'100%'} />
        </div>
    );


      var header;
      if(this.state.newitem===true)
      {
          header = <h1>New Item</h1>
      }
      else
      {
        header = <h1>Edit Item</h1>
 
      }

     return (
       <div>
         {header}
      
        <Form>
        <Form.Group controlId="formSku" className={`${this.errorClass(this.state.formErrors_sku)}`}>                
          <Form.Label>Item SKU</Form.Label>

          <Form.Control type="text" readOnly={this.props.readOnly} value={this.state.sku} onChange={this.onChangeSku.bind(this)}/>
                    
          <div className="ErrMsg"> {this.state.formErrors_sku}</div>
        </Form.Group>

        <Form.Group controlId="formStockname" className={` ${this.errorClass(this.state.formErrors_name)}`}>
          <Form.Label>Item Name</Form.Label>
          <Form.Control type="text" value={this.state.name} onChange={this.onChangeName.bind(this)} />
          <div className="ErrMsg"> {this.state.formErrors_name}</div>

        </Form.Group>

        <Form.Group controlId="formPrice" className={` ${this.errorClass(this.state.formErrors_price)}`}>
          <Form.Label>Item Price</Form.Label>
          <Form.Control type="text" value={this.state.price} onChange={this.onChangePrice.bind(this)} />
          <div className="ErrMsg"> {this.state.formErrors_price}</div>

        </Form.Group>

        <Form.Group controlId="formCostPrice" className={` ${this.errorClass(this.state.formErrors_costprice)}`}>
          <Form.Label>Cost Price</Form.Label>
          <Form.Control type="text" value={this.state.costprice} onChange={this.onChangeCostPrice.bind(this)} />
          <div className="ErrMsg"> {this.state.formErrors_costprice}</div>

        </Form.Group>

        <Form.Group controlId="formStock" className={` ${this.errorClass(this.state.formErrors_stock)}`}>
          <Form.Label>In Stock</Form.Label>
          <Form.Control type="text" value={this.state.qty} onChange={this.onChangeQty.bind(this)} />
          <div className="ErrMsg"> {this.state.formErrors_stock}</div>

        </Form.Group>

        <Form.Group controlId="formMPN" className={` ${this.errorClass(this.state.formErrors_mpn)}`}>
          <Form.Label>Manufacturer Part Number</Form.Label>
          <Form.Control type="text" value={this.state.mpn} onChange={this.onChangeMPN.bind(this)} />
          <div className="ErrMsg"> {this.state.formErrors_mpn}</div>

        </Form.Group>

        <Form.Group controlId="formBRAND" className={` ${this.errorClass(this.state.formErrors_brand)}`}>        
        <Form.Label>Brand</Form.Label>
        <BrandsDropDown baseUrl={this.props.baseUrl} jwt={this.props.jwt} title={this.state.brandid} onChangeBrand={this.onChangeBrand.bind(this)}/>
        <div className="ErrMsg"> {this.state.formErrors_brand}</div>

        </Form.Group>

        <Form.Group controlId="formSUPPLIER" className={` ${this.errorClass(this.state.formErrors_supplier)}`}>        
        <Form.Label>Supplier</Form.Label>
        <SuppliersDropDown baseUrl={this.props.baseUrl} jwt={this.props.jwt} name={this.state.supplierid} onChangeSupplier={this.onChangeSupplier.bind(this)}/>
        <div className="ErrMsg"> {this.state.formErrors_supplierid}</div>
        </Form.Group>

        
        
        <Form.Group controlId="formDPT" className={` ${this.errorClass(this.state.formErrors_dpt)}`}>        
        <Form.Label>Department</Form.Label>
        <DepartmentDropDown baseUrl={this.props.baseUrl} jwt={this.props.jwt} title={this.state.department} onChangeDepartment={this.onChangeDepartment.bind(this)}/>
        <div className="ErrMsg"> {this.state.formErrors_dpt}</div>
        </Form.Group>

        <Form.Group controlId="formVAT" className={` ${this.errorClass(this.state.formErrors_vat)}`}>        
        <Form.Label>VAT</Form.Label>
        <VatDropDown baseUrl={this.props.baseUrl} jwt={this.props.jwt} title={this.state.vat} onChangeVat={this.onChangeVat.bind(this)}/>
        <div className="ErrMsg"> {this.state.formErrors_vat}</div>
        </Form.Group>

        <Form.Group controlId="formOnline">        
        <Form.Label>is Online?</Form.Label>
       
       
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {this.state.online}
          </Dropdown.Toggle>

          <Dropdown.Menu >
 
          <Dropdown.Item onSelect={this.onChangeOnline.bind(this,"yes")} key="yes">yes</Dropdown.Item>
          <Dropdown.Item onSelect={this.onChangeOnline.bind(this,"no")} key="no">no</Dropdown.Item>
    
        </Dropdown.Menu>
        </Dropdown>



        </Form.Group>



        <Button variant="primary" onClick={this.handleSubmit.bind(this)} > 
          Submit
        </Button>

        
      </Form>

       </div>
     )
   }
 }
 
 export default withRouter(EditItem)
 